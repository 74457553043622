import { useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import Notifications from 'react-notify-toast';

import { INTAKE_TODO_TITLE } from 'components/ToDoItems/components/constants';
import { TODOS } from 'kb-shared/graphql/queries';
import { TodoResult } from 'screens/Book/steps/Success/PurchaseConfirmation/PurchaseConfirmation.types';
import { analytics } from 'utilities/analytics';

import AuthGuard from './components/AuthGuard';
import { IntakeFormNotAvailableModal } from './components/IntakeFormNotAvailableModal/IntakeFormNotAvailableModal';
import { IntakeFormRoute } from './routes/IntakeFormRoute';
import { AbsoluteCentered } from './routes/IntakeFormRoute.styled';

export const IntakeForm = () => {
  const { data, loading } = useQuery(TODOS) as TodoResult;
  const [formUnavailable, setFormUnavailable] = useState(false);

  useEffect(() => {
    analytics.page(analytics.PAGES.PATIENT_INTAKE);
  }, []);

  if (loading || data == null) return null;

  const hasIntakeFormTodo = Boolean(
    data?.patientTodos.find(todo => todo.title === INTAKE_TODO_TITLE)
  );

  return (
    <>
      <AuthGuard>
        {hasIntakeFormTodo && !formUnavailable && (
          <IntakeFormRoute onFormBecomeUnavailable={() => setFormUnavailable(true)} />
        )}
        {(!hasIntakeFormTodo || formUnavailable) && (
          <AbsoluteCentered>
            <IntakeFormNotAvailableModal
              visible={true}
              onDismiss={() => (window.location.href = '/')}
            />
          </AbsoluteCentered>
        )}
      </AuthGuard>
      <Notifications />
    </>
  );
};
