import { todoWidgetElementId } from 'components/ToDoItems/ToDoItems.constants';
import { MenuItemDetails } from 'components/v2/DropdownMenu/Dropdown.types';
import { MedicationsInstructions, DailyInstruction } from 'kb-shared';
import {
  formatToWeekday,
  formatToShortDate,
  momentWithParsedTimeZone,
  formatToLongWeekdayMonthDay
} from 'kb-shared/utilities/momentHelpers';

import { MedicationInstruction } from './MedicationsWidget.types';

const safeDateValue = (date: string | undefined | null): string => {
  return date || '';
};

export const getFormattedTitleLabel = (instruction: DailyInstruction): string => {
  const isToday = instruction.dateLabel === 'Today';
  const date = safeDateValue(instruction.date);
  return isToday ? 'Today' : formatToWeekday(momentWithParsedTimeZone(date)) || '';
};

export const getFormattedDateLabel = (instruction: DailyInstruction): string => {
  return formatToShortDate(momentWithParsedTimeZone(safeDateValue(instruction.date))) || '';
};

export const getLongFormattedDateLabel = (instruction: DailyInstruction): string => {
  return (
    formatToLongWeekdayMonthDay(momentWithParsedTimeZone(safeDateValue(instruction.date))) || ''
  );
};

export const areInstructionsMissing = (data: MedicationsInstructions | undefined) => {
  return !data?.nextInstructions?.length;
};

export const scrollToTodoWidget = () => {
  const todoWidget = document.getElementById(todoWidgetElementId);

  if (todoWidget) {
    todoWidget.scrollIntoView({ behavior: 'smooth' });
  }
};

export const getOpenInstructions = (
  allInstructions: Array<MedicationInstruction>,
  selectedIndex: number
): MedicationInstruction[] => {
  if (selectedIndex < 0 || selectedIndex >= allInstructions.length) return [];
  if (allInstructions.length <= 3) return allInstructions;

  // special cases -> first and last instruction
  if (selectedIndex === 0) return allInstructions.slice(0, 3);
  if (selectedIndex === allInstructions.length - 1) return allInstructions.slice(-3);

  // return the item before, at, and after selectedIndex
  const startIndex = Math.max(0, selectedIndex - 1);
  const endIndex = Math.min(allInstructions.length, selectedIndex + 2);

  return allInstructions.slice(startIndex, endIndex);
};

export const getInstructionsAfter = (
  allInstructions: Array<MedicationInstruction>,
  currentlyDisplayedItems: Array<MedicationInstruction>,
  onClick: (index: number) => void
): MenuItemDetails[] => {
  if (currentlyDisplayedItems.length === 0) return [];

  const lastVisibleIndex = currentlyDisplayedItems[currentlyDisplayedItems.length - 1].index;
  const nextInstructions = allInstructions.filter(x => x.index > lastVisibleIndex);

  return formatInstructionsToDropdownItems(nextInstructions, onClick);
};

export const getInstructionsBefore = (
  allInstructions: Array<MedicationInstruction>,
  currentlyDisplayedItems: Array<MedicationInstruction>,
  onClick: (index: number) => void
): MenuItemDetails[] => {
  if (currentlyDisplayedItems.length === 0) return [];

  const firstVisibleIndex = currentlyDisplayedItems[0].index;
  const previousInstructions = allInstructions.filter(x => x.index < firstVisibleIndex);

  return formatInstructionsToDropdownItems(previousInstructions, onClick);
};

const formatInstructionsToDropdownItems = (
  instructions: MedicationInstruction[],
  onClick: (index: number) => void
) => {
  return instructions.map(
    instruction =>
      ({
        label: instruction.titleLabel + ', ' + instruction.dateLabel,
        isDisabled: false,
        type: 'default',
        rightSideText:
          instruction.data.drugs.length !== 0
            ? instruction.data.drugs.length.toString()
            : undefined,
        onClick: () => onClick(instruction.index)
      } as MenuItemDetails)
  );
};

export const getInstructionForMobile = (
  allInstructions: Array<MedicationInstruction>,
  showAllInstructions: boolean
): MedicationInstruction[] => {
  if (allInstructions.length === 0) return [];
  if (showAllInstructions) return allInstructions;

  return [allInstructions[0]];
};
